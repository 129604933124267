import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const FourteenInvaluableHabitsPage = () => {
    const article = {
        id: '54b301d2-9a5a-5510-a120-6f6d0bb51a1a',
        title: '14 Invaluable Habits: What to Do With Your Money',
        slug: '/14-invaluable-habits/',
        date: '2018-03-01T00:01:18.000Z',
        modified: '2021-11-05T16:34:15.000Z',
        excerpt: 'Healthy finances start with good habits. These 14 money habits will put you in control of your finances and help you meet your goals.',
        featured_image: {
            source_url: '/media/piggy-bank-managing-money.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 17,
        content: (
            <>
                <p>
                    Americans are terrible with money. Many have no
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=14-invaluable-habits&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    to fall back on and are buried in debt.
                </p>
                <p>In our consumer economy, it&rsquo;s no surprise that we spend too much. Buying a new house or the latest phone is practically ingrained in the national psyche.</p>
                <p>
                    Spending beyond our means is as easy as swiping a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=14-invaluable-habits&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    .
                </p>
                <p>
                    The average borrower
                    {' '}
                    <a href="https://www.debtconsolidation.com/consumer-debt-in-america/">has $2,859 in credit card debt</a>
                    . American household debt is
                    {' '}
                    <a href="https://www.newyorkfed.org/newsevents/news/research/2017/rp170815" target="_blank" rel="noreferrer">worse than ever before</a>
                    : a staggering $12.73 trillion.
                </p>
                <p>Bad financial habits are easy to get into but hard to break. They create recurring problems that lead to long-term damage.</p>
                <p>
                    Families who don&rsquo;t buy health
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=14-invaluable-habits&amp;sub2=insurance" rel="noopener noreferrer" target="_blank">insurance</a>
                    {' '}
                    can go bankrupt. Failing to save for retirement can leave older adults destitute.
                </p>
                <p>
                    Nearly half of American families
                    {' '}
                    <a href="http://www.epi.org/publication/retirement-in-america/#charts" target="_blank" rel="noreferrer">have no retirement savings</a>
                    {' '}
                    at all. Nor do they have the money to cover a $400 emergency.
                </p>
                <p>Here&rsquo;s the good news: Personal habits largely determine the state of our finances. So you have the power to improve them.</p>
                <p>The sooner you change your habits, the sooner they will become second nature.</p>
                <p>Small changes now can have a lasting impact on your financial health.</p>
                <p>Starting a retirement fund will pay dividends years from now. Skipping your morning latte saves hundreds of dollars a year.</p>
                <LazyLoadImage alt="Amount of Money Consumers Spend on Coffee in 2017" src="/media/amount-of-money-consumers-spend-on-coffee-in-2017-1.png" />
                <p>Start by calculating how much you earn and how much you spend. You&rsquo;ve just laid the groundwork for a budget.</p>
                <p>Budget in hand, you have the tools to control your cash flow, plan for the future, and adopt good habits.</p>
                <h2 id="make-a-budget-and-stick-to-it">Make a Budget and Stick to It</h2>
                <p>Budgeting is a plan for how to use your money strategically.</p>
                <p>
                    It&rsquo;s not that complicated.
                    {' '}
                    <a href="https://nfcc.org/wp-content/uploads/2017/03/NFCC_BECU_2017-FLS_datasheet-with-key-findings.pdf?_ga=2.202713047.734360750.1493845051-1403476521.1491225914" target="_blank" rel="noreferrer">Yet 60% of Americans don&rsquo;t bother</a>
                    , according to the 2017 Consumer Financial Literacy Survey, published by the National Foundation for Credit Counseling.
                </p>
                <p>
                    It&rsquo;s no coincidence that so many of us are in debt. The average American borrower owes $42,732 in mortgage payments, auto loans,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=14-invaluable-habits&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    , and
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=14-invaluable-habits&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    bills.
                </p>
                <p>Budgeting helps track your spending, save for retirement, and pay off loans. It forces you to plan ahead for a major purchase or a trip—without going into credit card debt.</p>
                <p>The strategy for creating a budget depends on your preference. They all start by pulling out a calculator.</p>
                <h3>Figure out how much you earn after taxes (i.e., take-home pay)</h3>
                <p>
                    Tally up your fixed monthly expenses. These include rent,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=14-invaluable-habits&amp;sub2=insurance" rel="noopener noreferrer" target="_blank">insurance</a>
                    , transit pass, and loan payments.
                </p>
                <p>
                    Then factor in financial goals. Decide how much you want to put in your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=14-invaluable-habits&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    account, invest, or contribute to a retirement fund.
                </p>
                <p>Now subtract fixed expenses and savings from your take-home pay. What&rsquo;s left is your discretionary spending, like clothing or eating out.</p>
                <p>Or, break down your budget according to the 50/30/20 method:</p>
                <p>50% goes to necessities</p>
                <p>20% goes to savings</p>
                <p>30% is discretionary spending</p>
                <h3>Digital tools and apps simplify the whole process</h3>
                <p>
                    With
                    {' '}
                    <a href="https://www.youneedabudget.com/" target="_blank" rel="noreferrer">You Need a Budget</a>
                    {' '}
                    (YNAB) you can create a monthly budget based on your income, spending habits, and savings goals.
                </p>
                <p>
                    One of the most popular services is
                    {' '}
                    <a href="https://www.mint.com/" target="_blank" rel="noreferrer">Mint</a>
                    . It imports your bank account and credit card information and tells you how much you can spend based on your financial goals.
                </p>
                <p>Whatever method you choose, creating a budget will keep your finances on track.</p>
                <h2 id="get-financially-literate">Get Financially Literate</h2>
                <p>Money is daunting, so some of us never bother to learn about it.</p>
                <p>
                    Only 37% of Americans could answer
                    {' '}
                    <a href="http://www.prweb.com/releases/2016/07/prweb13579814.htm" target="_blank" rel="noreferrer">five basic finance questions</a>
                    {' '}
                    in a survey by the FINRA Investor Education Foundation in 2016.
                </p>
                <p>Financial literacy means understanding basic principles like what a checking account is and how credit cards work.</p>
                <p>
                    <strong>Learn basic financial principles.</strong>
                    {' '}
                    People who don&rsquo;t understand these are more likely to end up broke.
                </p>
                <p>Before the Great Recession, unwitting consumers took out sketchy mortgages that led to the financial crash.</p>
                <p>People with poor credit took out subprime mortgages to buy a house, not realizing that the deal came with high-interest rates they couldn&rsquo;t pay.</p>
                <p>
                    Yet 10 years later, the public is no more educated. According to a 2016 Allianz survey of 10,000 people in Western Europe, many still
                    {' '}
                    <a href="http://gflec.org/initiatives/money-finlit-risk/" target="_blank" rel="noreferrer">don&rsquo;t understand finances or risk</a>
                    .
                </p>
                <p>It found that people with high financial and risk literacy were twice as likely to make smart financial decisions.</p>
                <p>
                    <strong>Learn how to read the fine print.</strong>
                    {' '}
                    Before you sign for a loan or mortgage, you should understand how interest rates work.
                </p>
                <p>A solid grasp of financial terms will help you understand these complicated documents and avoid surprises.</p>
                <p>
                    To start taking control of your finances,
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-throughout-history/">learn how money works</a>
                    {' '}
                    and read a book on personal finance 101.
                </p>
                <h2 id="track-your-spending">Track Your Spending</h2>
                <p>How often have you wondered where all your money went?</p>
                <p>It&rsquo;s not hard to find out. Just look at your receipts and credit card statements.</p>
                <p>It can be shocking to see the numbers all laid out, so many people avoid it.</p>
                <p>To take control of your finances, it is crucial to track your spending and know where your money goes.</p>
                <p>Creating a budget helps you do this. When you become conscious of these patterns, you can control your spending.</p>
                <h3>Wait before you buy</h3>
                <p>Impulse purchases can wreak havoc on the most well-intentioned budgets.</p>
                <p>To avoid buyer&rsquo;s remorse, set rules for yourself. Promise to stick to a shopping list, for instance, or turn off one-click buying online.</p>
                <p>Set a limit of, say, $50 on non-essential purchases. Then wait 48 hours to decide whether it&rsquo;s worth the money.</p>
                <p>For major expenditures, like a new car, do your homework first. Read reviews or consumer reports to see what the experts have to say.</p>
                <h3>Hunt for bargains</h3>
                <p>In this age of promo codes and flash sales, it&rsquo;s silly to pay full price.</p>
                <p>There&rsquo;s no need to clip coupons anymore. You can often find discount codes online—they&rsquo;re just a quick search away.</p>
                <LazyLoadImage alt="2016 Most Popular Discount and Promo Code Websites" src="/media/2016-most-popular-discount-and-promo-code-websites-2.png" />
                <p>Wait for sales. Some items are more likely to go on sale during certain times of the year, like mattresses in May or outdoor furniture in July.</p>
                <p>You may end up buying things months in advance, but it will save money in the long run.</p>
                <h2 id="look-for-small-ways-to-save">Look for Small Ways to Save</h2>
                <p>Don&rsquo;t overlook the little ways you could save money on a daily basis. They add up over time.</p>
                <p>A paperback book that costs $15 is free when you use the library. The library stocks DVDs and e-books as well.</p>
                <p>
                    The Department of Energy says you can
                    {' '}
                    <a href="https://energy.gov/energysaver/thermostats" target="_blank" rel="noreferrer">save 10% on heating costs by turning down your thermostat</a>
                    {' '}
                    7-10 degrees for eight hours a day.
                </p>
                <p>Programmable thermostats will do this for you automatically.</p>
                <h3>Skip the take-out</h3>
                <p>Many people allow themselves small treats, like coffee or take-out lunch. Yet multiply them by several months and they become four-figure expenditures.</p>
                <p>In a big city, take-out costs $8-$10. That&rsquo;s $40-$50 a week and around $2,000 a year. Packing lunch will save you a small fortune.</p>
                <h3>Plan your meals</h3>
                <p>Plan dinner menus to avoid costly (and often unhealthy) last-minute meals like take-out pizza.</p>
                <p>When you go shopping, you will only buy the ingredients you need.</p>
                <p>
                    Menu planning may sound exhausting, but online tools make it easy.
                    {' '}
                    <a href="https://www.plantoeat.com/" target="_blank" rel="noreferrer">Plan to Eat</a>
                    {' '}
                    lets you import online recipes, plop them on a calendar, and then automatically creates a shopping list.
                </p>
                <p>Dinner leftovers also double as lunch. One night&rsquo;s roast chicken becomes the next day&rsquo;s chicken salad. You&rsquo;ll save on two meals!</p>
                <h2 id="build-an-emergency-fund">Build an Emergency Fund</h2>
                <p>An emergency fund is a pool of cash set aside for unexpected expenses: a job loss, surprise medical expense, or home damage.</p>
                <p>You tap into the fund only when you are truly strapped, and not, I repeat NOT, to pay for a vacation.</p>
                <p>Aim to save enough to cover three to six months of expenses (like rent and food).</p>
                <h3>Emergency planning is responsible</h3>
                <p>A rainy day fund helps prepare you for inevitable financial surprises.</p>
                <p>Without one, you and your family could end up in a tailspin, saddled with debt.</p>
                <p>You may have to borrow money from family, take out a payday loan, or rely on a credit card for basic purchases.</p>
                <p>
                    Yet many Americans lack any form of emergency savings. 53% don&rsquo;t have enough
                    {' '}
                    <a href="https://www.federalreserve.gov/2015-report-economic-well-being-us-households-201605.pdf" target="_blank" rel="noreferrer">to cover three months of expenses</a>
                    .
                </p>
                <h3>Set achievable savings goals</h3>
                <p>No amount is too little to save.</p>
                <p>You can start by putting aside $1 a day. That may not sound like a lot, but it will add up to $365 after a year.</p>
                <p>
                    Put that into a high-interest savings account like
                    {' '}
                    <a href="https://www.ally.com/" target="_blank" rel="noreferrer">Ally</a>
                    , which pays 1.25%, and you will earn an extra $5 a year.
                </p>
                <p>Work those savings goals into your budget. Saving should be part of the plan, not an afterthought.</p>
                <p>Regularly review your savings goals as well. If you get a raise, maybe you can set aside a bit more.</p>
                <p>The more you contribute to your savings, the more it will grow.</p>
                <p>If you save $5 a day, it will add up to $1,825 in a year and earn over $20 in interest. That&rsquo;s enough to cover a typical out-of-pocket medical cost.</p>
                <h2 id="automate-your-finances">Automate Your Finances</h2>
                <p>Life becomes so much easier when you automate your finances. It removes human error and willpower from the equation, so you&rsquo;re more likely to stick to your goals.</p>
                <h3>Set up and pay all your bills online</h3>
                <p>When you automate your bills, you never forget a payment.</p>
                <p>Things like gym memberships, newspaper subscriptions, and phone bills can be charged automatically to your credit card or deducted from your bank account.</p>
                <p>
                    Automated payments are especially important with credit cards. When you miss a deadline, the
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-card-debt/">credit card company charges a late fee</a>
                    . That gets added to your unpaid balance, which accumulates high interest.
                </p>
                <h3>Divert a portion of your income to savings</h3>
                <p>Take advantage of automatic paycheck deductions to divert a portion of your income to a savings account. Since you&rsquo;ll never even see it, you won&rsquo;t be tempted to spend it.</p>
                <p>A smart way to do this is to open up a savings account that&rsquo;s completely separate from your checking account.</p>
                <p>This prevents you from easily transferring money between accounts.</p>
                <p>You can also send money to an investment account or retirement fund. Whatever your financial goals, computers can help you stick to them.</p>
                <LazyLoadImage alt="Comparison between Millennial Investors and U.S. Investors in General" src="/media/comparison-between-millennial-investors-and-u-s-investors-in-general-3.png" />
                <h2 id="pay-off-debt">Pay Off Debt</h2>
                <p>It&rsquo;s hard to build wealth when you&rsquo;re in the red.</p>
                <p>
                    Many people start their adult lives with debt and struggle to climb out of it. The average college graduate has some
                    {' '}
                    <a href="https://www.usatoday.com/story/money/personalfinance/2017/04/28/average-student-loan-debt-every-state/100893668/" target="_blank" rel="noreferrer">$30,000 in student loans</a>
                    .
                </p>
                <p>
                    It may sound daunting to repay debt, but it should be a top priority for anyone who wants control over their finances. Luckily there&rsquo;s plenty of
                    {' '}
                    <a href="https://www.debtconsolidation.com/">advice on how to get out of debt</a>
                    .
                </p>
                <p>
                    Use a debt reduction calculator to figure out how long this process will take. A credit counselor can
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-management-program/">offer guidance and help you come up with a plan</a>
                    .
                </p>
                <h3>Devise a strategy to pay off loans</h3>
                <p>There are two primary methods for tackling debt.</p>
                <p>
                    <strong>Start by paying off high-interest loans first.</strong>
                    {' '}
                    If you have multiple loans, rank them in order of interest rate. You want to pay those with the highest interest rates (like credit cards) first.
                </p>
                <p>
                    <strong>Another option is the &quot;snowball method.&quot;</strong>
                    {' '}
                    Rank your loans from smallest to largest and pay off the smallest ones first.
                </p>
                <p>The theory is that when you get rid of one loan, it builds momentum to pay others.</p>
                <p>Whichever strategy you choose, put as much as you can towards paying off your loans. The longer it takes to do, the more you will shell out in interest.</p>
                <p>Try to make more than the minimum payment each month. Consider automating loan repayments so it goes directly from your paycheck.</p>
                <p>Put any extra money, like a tax return, towards paying the debt.</p>
                <p>
                    Or consider a
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/">debt consolidation loan</a>
                    . This pools your loans together so you make only one payment each month, often at a lower interest rate.
                </p>
                <h3>Stay out of debt</h3>
                <p>Once out of debt, stay out of debt.</p>
                <p>Learn from past mistakes and examine how you got into debt in the first place.</p>
                <p>If you tend to overspend with credit cards, use cash for more transactions and limit yourself to one credit card.</p>
                <p>
                    Use your money wisely. With a budget and savings account, you can
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-management/">be smart about how you manage your money</a>
                    .
                </p>
                <p>
                    If you have an emergency fund, you won&rsquo;t have to rely on payday
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=14-invaluable-habits&amp;sub2=loans" rel="noopener noreferrer" target="_blank">loans</a>
                    {' '}
                    or credit cards.
                </p>
                <h2 id="open-up-about-money">Open Up About Money</h2>
                <p>Money is taboo in America. You&rsquo;re more likely to know about a friend&rsquo;s sex life than her salary.</p>
                <p>Money is a major source of stress in relationships, yet couples are often uncomfortable broaching the subject.</p>
                <p>It&rsquo;s a bad idea to shut down this conversation. Opening up about money can improve our financial well-being as well as our relationships.</p>
                <h3>Talk about your financial struggles</h3>
                <p>It&rsquo;s hard to tackle debt or build emergency savings alone. Enlist support from a trusted friend who will root for you and hold you accountable.</p>
                <p>
                    If friends know that you are focused on paying off
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=14-invaluable-habits&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    , they might not tempt you with an invitation to an expensive restaurant.
                </p>
                <p>In a relationship, be open about your goals. Your partner can support you and provide a check when you&rsquo;re deciding on a major purchase.</p>
                <p>
                    You can also join a get-out-of-debt support group like
                    {' '}
                    <a href="http://www.debtorsanonymous.org/" target="_blank" rel="noreferrer">Debtors Anonymous</a>
                    .
                </p>
                <h3>Hire a financial advisor</h3>
                <p>A financial advisor can help you with big decisions like opening a college fund and planning for retirement.</p>
                <p>You should discuss short-term and long-term goals, your spending habits, risk tolerance, and insurance needs.</p>
                <p>However, hiring a financial advisor costs money, so you&rsquo;ll have to weigh whether it&rsquo;s worth it for you. It&rsquo;s especially useful when you&rsquo;re nearing retirement or starting a family.</p>
                <h2 id="use-credit-cards-wisely">Use Credit Cards Wisely</h2>
                <p>Credit cards are essentially interest-free loans—when used properly.</p>
                <p>By putting purchases on the card, you borrow money and pay no interest for a month.</p>
                <p>If you&rsquo;re savvy, you can also earn cash back, free flights, and other rewards.</p>
                <p>
                    <strong>Credit cards are only useful if you pay the balance each month.</strong>
                    {' '}
                    Only use them for things you can actually afford.
                </p>
                <p>Otherwise, you will pay ridiculously high-interest rates (as much as 22%) on the money you owe.</p>
                <p>
                    Many Americans play right into the hands of credit card companies. The average family
                    {' '}
                    <a href="https://www.cnbc.com/2017/05/17/how-much-the-average-us-family-has-in-credit-card-debt.html" target="_blank" rel="noreferrer">carries $8,377 in credit card debt</a>
                    .
                </p>
                <p>Ignore the minimum amount due on your bill and pay off the entire balance. Set up automatic payments so you never miss a payment.</p>
                <p>
                    If you do rack up unpaid balances,
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-card-debt/">pay them down as soon as possible</a>
                    .
                </p>
                <p>
                    <strong>When you get your bill, read it line-by-line.</strong>
                    {' '}
                    Don&rsquo;t just look at the total balance, but examine statements for errors or suspicious activity.
                </p>
                <h2 id="focus-on-your-physical-and-mental-health">Focus on Your Physical and Mental Health</h2>
                <p>Money is an intrinsic part of our everyday lives. It stands to reason that our personal habits affect our spending habits.</p>
                <p>
                    Be healthy. Your health and financial well-being are linked. Having too much debt can
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-management/">strain our relationships and hurt our mental health</a>
                    .
                </p>
                <h3>Healthy personal habits predict smart financial habits and vice versa</h3>
                <p>
                    A Duke University study found that low credit scores
                    {' '}
                    <a href="http://www.pnas.org/content/111/48/17087.short" target="_blank" rel="noreferrer">predict the risk of cardiovascular disease</a>
                    .
                </p>
                <p>
                    Similarly, people who contribute to a 401(K) are
                    {' '}
                    <a href="http://journals.sagepub.com/doi/abs/10.1177/0956797614540467" target="_blank" rel="noreferrer">more likely to improve their health</a>
                    , according to researchers at Washington University in St. Louis.
                </p>
                <p>Poor health does not cause poor financial decisions, but people who exercise tend to be disciplined in other areas of their life.</p>
                <p>There&rsquo;s a ripple effect.</p>
                <p>Exercise improves cognitive function and energy levels, reduces stress, and helps you get a good night&rsquo;s sleep.</p>
                <p>
                    <a href="https://www.mayoclinic.org/healthy-lifestyle/stress-management/in-depth/exercise-and-stress/art-20044469" target="_blank" rel="noreferrer">All of these factors make you productive</a>
                    {' '}
                    at work, affecting your earning potential.
                </p>
                <h3>Think long-term</h3>
                <p>Visualize your goals. Think about what you want in life and where you want to be in 30 years.</p>
                <LazyLoadImage alt="2017 Americans Retirement Goals" src="/media/2017-americans-retirement-goals-4.png" />
                <p>It&rsquo;s easier to create a financial roadmap if you have a destination in mind.</p>
                <p>This means thinking long term and not looking for quick wins. Take steps to plan for retirement and make investments that will pay dividends years from now.</p>
                <p>Avoid thinking only about the short term, which fuels emotional decisions. It&rsquo;s a long game.</p>
                <h2 id="watch-your-credit-score">Watch Your Credit Score</h2>
                <p>A credit score is like your personal financial grade. The higher the score, the better the grade.</p>
                <p>Anything over 740 is considered Excellent. Below 600 is Bad.</p>
                <p>
                    When you take out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=14-invaluable-habits&amp;sub2=loan" rel="noopener noreferrer" target="_blank">loan</a>
                    , mortgage, or credit card, the issuer will check your credit score.
                </p>
                <p>If you have poor credit, lenders will reject you or charge high interest.</p>
                <p>Your credit score also affects whether you can rent an apartment or get a cell phone plan.</p>
                <p>You should know your credit score and, if necessary, take steps to improve it.</p>
                <h3>Check your credit report</h3>
                <p>Your credit score is based on your credit report, so you should know what&rsquo;s on there.</p>
                <p>By law, you can request a free copy of your credit report once every 12 months from each of the three main credit reporting agencies: Equifax, Experian, and TransUnion.</p>
                <p>
                    You can check it on
                    {' '}
                    <a href="https://www.annualcreditreport.com/index.action" target="_blank" rel="noreferrer">Annualcreditreport.com</a>
                    .
                </p>
                <p>Now, check your credit report for errors. There&rsquo;s a good chance you&rsquo;ll find something.</p>
                <p>Look for accounts you never opened or an address you have never lived at. If you find an error, report and dispute it.</p>
                <h3>Repair your credit</h3>
                <p>
                    There are several steps you can take to
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-counseling/">improve your score</a>
                    .
                </p>
                <p>
                    <strong>Pay bills on time.</strong>
                    {' '}
                    Your payment history makes up 35% of your credit score.
                </p>
                <p>Bills for utilities, doctors, and credit cards all factor in. Set up reminder alerts or automatic payments to be sure you make deadlines.</p>
                <p>
                    <strong>Don&rsquo;t put too much on credit cards.</strong>
                    {' '}
                    Another 30% of your score is made up by your credit utilization, meaning how much of your available credit you have used.
                </p>
                <p>Aim to use no more than 30% of your limit. If you have a $10,000 limit on your credit card, spend less than $3,000 each month.</p>
                <p>
                    <strong>Don&rsquo;t close old accounts.</strong>
                    {' '}
                    The length of your credit history factors in, so you want to show that you&rsquo;ve been paying bills on time for years.
                </p>
                <p>While it may be tempting to cut up old cards, don&rsquo;t cancel them right away. It may be better to hold onto your older card than sign up for new ones.</p>
                <h2 id="save-for-retirement-now">Save for Retirement Now</h2>
                <p>Retirement is rarely top of mind when you&rsquo;re in your 20s, so many people neglect to start saving. Bad move.</p>
                <p>The earlier you start saving for retirement, the more you will have when you&rsquo;re 65. The more time your money has to grow, the more you will accumulate.</p>
                <p>Here&rsquo;s a sample scenario, assuming the market average of 7% returns.</p>
                <p>If you invest $100 per year starting at age 25, you will contribute $4,000 over 40 years. When you&rsquo;re 65, that money will have grown to $19,963.</p>
                <p>Let&rsquo;s say you start at 35 but contribute twice as much, $200 a year, for a total of $6,000 over 30 years. You&rsquo;ll end up with less money: $18,892 at age 65.</p>
                <p>It&rsquo;s important to save for retirement at any age, even if you start late.</p>
                <h3>Robo-advisors to the rescue</h3>
                <p>You don&rsquo;t have to be a Wall Street expert to know how to invest.</p>
                <p>Thanks to technology, you can save for retirement and big ticket items like a home or your kid&rsquo;s education by using a robo-advisor.</p>
                <p>A robo-advisor is simply a piece of software that helps you invest just as a financial planner or wealth manager would.</p>
                <p>
                    The beauty of a robo-advisor is that they&rsquo;re easier, just as effective (if not more),
                    {' '}
                    <em>and</em>
                    {' '}
                    cheaper!
                </p>
                <p>
                    Some good companies that use robo-advisor technology are
                    {' '}
                    <a href="http://www.betterment.com/" target="_blank" rel="noreferrer">Betterment</a>
                    ,
                    {' '}
                    <a href="http://www.wealthfront.com/" target="_blank" rel="noreferrer">Wealthfront</a>
                    , and
                    {' '}
                    <a href="https://www.schwab.com/public/schwab/investing/invest.html" target="_blank" rel="noreferrer">Charles Schwab</a>
                    .
                </p>
                <p>These companies will also help you open the type of accounts we recommend below – like an IRA – and get you started investing in the stock market.</p>
                <h3>Open an IRA</h3>
                <p>The smart way to save for retirement is with an IRA, which does not tax your investment returns. You can contribute up to $5,500 but you can&rsquo;t withdraw money until you&rsquo;re 50.</p>
                <p>There are two types of IRAs: A traditional IRA and a Roth IRA.</p>
                <p>
                    <strong>Contributions to a traditional IRA are tax-deductible.</strong>
                    {' '}
                    But you get taxed when you withdraw money in your 70s.
                </p>
                <p>You are required to withdraw money when you are 70 ½, at which point you can no longer make contributions.</p>
                <p>A traditional IRA is good for people who could use a large tax deduction now. It may allow you to contribute more when you are young since you&rsquo;re not paying any of it in taxes.</p>
                <p>It&rsquo;s best if you think your tax bracket will be the same or less when you hit retirement age.</p>
                <p>
                    <strong>Contributions to a Roth IRA are not deductible and will be taxed normally.</strong>
                    {' '}
                    But you will not be taxed when you withdraw money.
                </p>
                <p>There are no age limits on when you have to start withdrawing money, so your investments can continue to grow.</p>
                <p>A Roth IRA is best if you don&rsquo;t need the tax deduction now and think you will be in a higher income bracket when you retire.</p>
                <h3>Enroll in a 401(K) plan</h3>
                <p>A 401(K) is an employer-sponsored retirement savings plan.</p>
                <p>Employees automatically contribute a portion of their paycheck to invest. Some employers will match your contributions.</p>
                <p>You only pay taxes when you withdraw money.</p>
                <p>You can contribute up to $18,500 per year. Aim to increase your contributions consistently, especially if you get a raise.</p>
                <h2 id="invest-in-the-stock-market">Invest in the Stock Market</h2>
                <p>Given the ups and downs of the markets, buying stocks may seem like a risky investment.</p>
                <LazyLoadImage alt="Stock Ownership Since 2008 Financial Crisis" src="/media/stock-ownership-since-2008-financial-crisis-5.png" />
                <p>But in general, the stock market goes up. Over the long term, you get a great rate of return on investments.</p>
                <p>Despite recessions, the Dow Jones and S&amp;P 500 have trended steeply up over the last century.</p>
                <p>When buying stocks, think like Warren Buffett and make long-term investments. Don&rsquo;t pay attention to the immediate rate of return, but allow time for companies to grow.</p>
                <h3>Invest in index funds</h3>
                <p>If you&rsquo;re a novice, don&rsquo;t buy individual stocks. Instead, spread out your risk by investing in index funds.</p>
                <p>Index funds are a collection of stocks on a market index, such as the S&amp;P 500. If you have an S&amp;P index fund, your returns will match those on the S&amp;P.</p>
                <h3>Buy a stock that pays dividends</h3>
                <p>If buying individual stocks, focus on ones that pay dividends. Dividends are small cash payments that go to shareholders.</p>
                <p>Dividend-paying stocks tend to be well-established companies that are relatively low-risk, such as Coca-Cola.</p>
                <p>When you get dividends, reinvest them by purchasing more stock. This will generate more dividends, compounding your investment.</p>
                <h3>Invest what you can</h3>
                <p>You don&rsquo;t need a lot of money to buy stocks. Invest whatever you can and start building wealth.</p>
                <p>
                    Micro-investing apps like
                    {' '}
                    <a href="https://www.acorns.com/" target="_blank" rel="noreferrer">Acorns</a>
                    {' '}
                    allow you to contribute small sums. Linked to your credit card, it rounds up purchases to the nearest dollars and invests that spare change.
                </p>
                <h2 id="buy-insurance">Buy Insurance</h2>
                <p>It can be tempting to forgo insurance to save a little money. This is short-sighted and can lead to financial ruin.</p>
                <p>
                    What insurance you need depends on your situation, but everyone should have health
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=14-invaluable-habits&amp;sub2=insurance" rel="noopener noreferrer" target="_blank">insurance</a>
                    .
                </p>
                <h3>Health insurance</h3>
                <p>
                    According to a 2013 study, about
                    {' '}
                    <a href="http://time.com/money/4765443/obamacare-bankruptcy-decline/" target="_blank" rel="noreferrer">20% of personal bankruptcies are caused by medical debt</a>
                    . Those who file for bankruptcy have an average of $8,594 in medical debt.
                </p>
                <p>
                    Yet many Americans go without health insurance. According to the Kaiser Family Foundation,
                    {' '}
                    <a href="https://www.kff.org/uninsured/fact-sheet/key-facts-about-the-uninsured-population/" target="_blank" rel="noreferrer">27.6 million Americans were uninsured in 2016</a>
                    .
                </p>
                <p>Health insurance is available through employers or on a government Health Insurance Marketplace.</p>
                <p>Low-income Americans can get coverage through Medicaid or the Children&rsquo;s Health Insurance Program. Older Americans can use Medicare.</p>
                <h3>Other forms of insurance</h3>
                <p>Depending on your situation, you may need other kinds of insurance too.</p>
                <p>If you own a house, you&rsquo;ll want homeowners&rsquo; insurance. This protects your property from fire and flooding damage, as well as vandalism and theft.</p>
                <p>Fire and lightning can cause a whopping $43,000 in damage; water damage runs over $8,000.</p>
                <p>Renter&rsquo;s insurance, auto insurance, and disability insurance are other common plans.</p>
                <h3>You can start building good financial habits today</h3>
                <p>Financial health is in your control. Identify areas you could improve and start making changes now.</p>
                <p>Call a financial advisor, research your company&rsquo;s 401(K) plan, or start working out your budget. You&rsquo;ll see it&rsquo;s not that complicated.</p>
                <p>Make good financial habits second nature.</p>
                <p>This may take time, but if you have a plan and set rules for yourself, you&rsquo;ll set a new pattern of smart behavior.</p>
                <p>Do you have any invaluable &quot;money&quot; habits we&rsquo;ve missed?</p>
                <p>Let everyone know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default FourteenInvaluableHabitsPage;
